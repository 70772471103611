<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-show="!edition">
        <listitemspagecard
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'consultations'"
          :AllResponse="'allconsultations'"
          :headers="headers"
          :field="'DATE_CONS'"
          :filename="'consultations'"
          :sheetname="'consultations'"
          @open="NewConsultationForm"
          @rowselect="ConsultationChange"
          :showedit="false"
          :key="klist"
          :Add="editer"
          :search_elm="filtre"
        >
        </listitemspagecard>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="6" md="8">
        <consultationprint
          :key="kprint"
          :consult="consultation"
          :editer="editer"
          :valider="valider"
          :cloturer="cloturer"
          :dem_products="dem_products"
          @cancel="consultation = selitem"
          @change="ConsultationChange"
          :edition="edition"
          @edition="edit_change"
        >
        </consultationprint>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
    </v-row>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CONSULTATIONS from "../graphql/Consult/CONSULTATIONS.gql";
import ALLCONSULTATIONS from "../graphql/Consult/ALLCONSULTATIONS.gql";
import DELETE_CONSULTATION from "../graphql/Consult/DELETE_CONSULTATION.gql";
import ALLDEMANDES_DETAIL from "../graphql/Demande/ALLDEMANDES_DETAIL.gql";

export default {
  components: {
    listitemspagecard: () => import("../components/ListItemsPagesCard.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    consultationprint: () => import("../components/ConsultPrint.vue"),
  },
  props: {},
  data: () => ({
    filtre: "",
    editer: false,
    cloturer: false,
    valider: false,
    klist: 100,
    drawer: null,
    selitem: {},
    edition: false,
    headers: [
      {
        text: "",
        value: "no_cons",
        enum: "NO_CONS",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "",
        value: "label",
        enum: "LABEL",
        title: true,
        selected: true,
        sm: 8,
        md: 8,
      },

      {
        text: "Date",
        value: "date_cons",
        enum: "DATE_CONS",
        slot: "date",
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Offres",
        value: "nb_offre",
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "",
        value: "statut_name",
        enum: "STATUT_NAME",
        align: "text-center",
        expire: true,
        selected: true,
        sm: 4,
        md: 4,
      },
    ],
    dem_products: [],
    consultation: {},
    kprint: 100,
    loading: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    Qselect() {
      return CONSULTATIONS;
    },
    Qselectall() {
      return ALLCONSULTATIONS;
    },
    Qdelete() {
      return DELETE_CONSULTATION;
    },
  },
  watch: {},

  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02071") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03071") || this.$store.state.isadmin;
    this.cloturer =
      this.$store.state.auth.includes("04071") || this.$store.state.isadmin;

    await this.get_data();

    if (this.$route.params.cmd_id) {
      this.consultation.id = -1;
      this.consultation.cmd_id = this.$route.params.cmd_id;
      this.edition = true;
      this.kprint++;
    }
  },

  methods: {
    edit_change(value) {
      this.edition = value;
    },
    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },

    async get_data() {
      let v = {
        Reste: 1,
        TypeScope: 1,
      };
      let r = await this.requette(ALLDEMANDES_DETAIL, v);
      if (r) this.dem_products = r.alldemandesdetail;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "cache-first",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    ConsultationChange(item) {
      this.kprint++;
      this.consultation = item;
      this.selitem = item;
      this.filtre = null;
    },
    NewConsultationForm(item) {
      this.edition = true;
      this.$store.dispatch("Editing", true);
      this.kprint++;
      this.consultation = item;
    },
  },
};
</script>
